import { Button, FormControl, FormControlLabel, FormLabel, Link, TextField, Typography } from '@material-ui/core';
import React, { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AuthenticationActionCreator from '../../actions/AuthenticationActionCreator';
import { getRegistrationMessage } from '../../config/errorMessages';
import isValidEmail from '../../core/typeguards/isValidEmail';
import useHistoryPush from '../../core/useHistoryPush';
import useUniqueId from '../../core/useUniqueId';
import { IRegistrationCredentials } from '../../models/authentication/registration/IRegistrationCredentials';
import { isError } from '../../models/core/error/IError';
import { AGB_PATH, DATA_PROTECTION_POLICY_PATH, MAIN_PATH } from '../../models/Paths';
import { Genders } from '../../models/user/Gender';
import { USER_DEFAULT_DATE } from '../../models/user/IUser';
import NotificationActionCreator from '../../services/Notifications/actions/NotificationActionCreator';
import { GenderSearchTraits, GenderTraits } from '../../temp/models/BerndUserTraits';
import ActivityIndicator from '../ActivityIndicator/ActivityIndicator';
import Checkbox from '../Checkbox';
import CountrySelect from '../CountrySelect/CountrySelect';
import DateSelect from '../DateSelect/DateSelect';
import GenderSelectButtonGroup from '../GenderSelectButtonGroup';
import LocationAutocomplete from '../LocationAutocomplete';
import Modal from '../Modal';
import md5 from 'md5'
import { BerndLoginArts } from '../../temp/models/IBerndLoginCredentials';

export interface IGoogleRegisterComponentProps {
    isOpen:boolean,
    loginresult:object
    handleClose:any
}

const defaultOptions = [
    { key: GenderTraits.MALE, text: 'Männlich' },
    { key: GenderTraits.FEMALE, text: 'Weiblich' },
    ];

const genderSearchOptions = [
    { key: GenderSearchTraits.MALE, text: 'Männer' },
    { key: GenderSearchTraits.FEMALE, text: 'Frauen' },
    ];

export const GoogleRegisterComponent = memo((props: IGoogleRegisterComponentProps) => {
    const [selectedDate, handleDateChange] = useState<Date>(USER_DEFAULT_DATE);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>();
    const [gender, setGender] = useState(GenderTraits.MALE);
    const [selectedZip, setSelectedZip] = useState<string | undefined>(undefined);
    const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined);
    const [selectedCountry, setSelectedCountry] = useState<string>('de');
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchGender, setSearchGender] = useState(GenderSearchTraits.FEMALE);
    const [optIn, setOptIn] = useState<boolean>(false);
    const [isOpen, setOpen] = useState<boolean>(false);



    const dispatch = useDispatch();
    const id = useUniqueId();

    const handleNext = useCallback(() => setActiveStep(1), []);
    const handleBack = useCallback(() => setActiveStep(0), []);

    useEffect(() => {
        setOpen(props.isOpen)
        const fbemail = props.loginresult["email"] as string
        if (fbemail !== undefined && isValidEmail(fbemail.trim())){
            setEmail(fbemail.trim())
        }
    }, [props.isOpen, props.loginresult]);

    // const handleClose = useHistoryPush(MAIN_PATH);

    //console.log(props.loginresult)

    const handleRegister = async () => {
        if ( 
            name !== '' &&
            gender &&
            selectedCity &&
            selectedZip &&
            selectedDate && 
            email
            ) {
            const register: IRegistrationCredentials = {
                name: name,
                email: email,
                dateOfBirth: selectedDate.toISOString(),
                gender: gender === GenderTraits.MALE ? Genders.MALE : Genders.FEMALE,
                country: selectedCountry,
                city: selectedCity,
                postalCode: selectedZip,
                deviceId: id,
                pushPreference: true,
                profileImageEncoded: '',
                firebaseId: '',
                platform: 'ios',
                preferredGender: searchGender,
                password: md5(props.loginresult["sub"]),
                google_id: props.loginresult["sub"] as string
            };

            setLoading(true);
            const result = await dispatch(AuthenticationActionCreator.registerUser(register));

            try {
                setLoading(false);
                setOpen(false)
                if (isError(result.payload.result)) {
                    dispatch(
                        NotificationActionCreator.enqueueSnackbar({
                            key: String(new Date().getTime() + Math.random()),
                            message:
                            'Registrierung Fehlgeschlagen!' +
                            getRegistrationMessage(
                                result.payload.result._embedded.errors.length > 0
                                ? result.payload.result._embedded.errors[0].message
                                : result.payload.result.message
                                ),
                            options: { variant: 'error' },
                        })
                        );
                } else {
                    /* dispatch(
                        NotificationActionCreator.enqueueSnackbar({
                            key: String(new Date().getTime() + Math.random()),
                            message: 'Geschafft! Du bist jetzt Startklar und kannst beginnen, dich mit Leuten aus deiner Umgebung zu verbinden.',
                            options: { variant: 'success' },
                        })
                    ); */
                }
            } catch (e) {
                // Pizdec
            }
            props.handleClose();
        } else {
            const errorArray: string[] = [];
            if (!name || name.trim() === '') {
                errorArray.push('Bitte gebe deinen Namen an.');
            }
            if (!email || email.trim() === '') {
                errorArray.push('Bitte gebe deine E-Mail Adresse an.');
            } else {
                if (!isValidEmail(email.trim())) {
                    errorArray.push('Bitte gebe eine gültige E-Mail Adresse an.');
                }
            }
            if (!selectedZip) {
                errorArray.push('Bitte gebe deinen Wohnort an.');
            }

            if (!optIn) {
                errorArray.push('Bitte bestätige die AGB');
            }

            for (const error of errorArray) {
                dispatch(
                    NotificationActionCreator.enqueueSnackbar({
                        key: String(new Date().getTime() + Math.random()),
                        message: error,
                        options: { variant: 'error' },
                    })
                    );
            }
        }
    };

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!/[^A-Za-z0-9]+/g.test(e.currentTarget.value)) {
            setName(e.currentTarget.value);
        }
    }, []);

    return (
        <Modal
        onClose={props.handleClose}
        open={isOpen}
        title="Registrierung vervollständigen"
        actionsClassName="flex column spacing triple padding bottom"
        actions={
                <>
                <Button color="secondary" onClick={handleRegister} disabled={!optIn || !selectedZip || name.length<5}>
                Jetzt registrieren!
                </Button>
                </>
            }
            >
            <>
            <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" style={{ textAlign: 'left' }} className="spacing margin bottom">
            Dein Name
            </FormLabel>

            <TextField
            fullWidth
                            // label="Dein Name"
            autoComplete="name"
                            // margin="dense"

            value={name}
            onChange={handleNameChange}
            />
            {!isValidEmail(email.trim()) && <br/>}
            {!isValidEmail(email.trim()) &&
            <FormLabel component="legend" style={{ textAlign: 'left' }} className="spacing margin bottom">
            Deine Email
            </FormLabel>
            }
            {!isValidEmail(email.trim()) &&
            <TextField
                style={{ marginBottom: 16 }}
                fullWidth
                type="email"
                id="email-input"
                key="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
            />
            }
            </FormControl>
            <FormControl className="spacing margin top" variant="outlined" fullWidth margin="dense">
            <CountrySelect
            selectedCountry={selectedCountry}
            onCountryChange={(newCountry: string) => {
                setSelectedCity(undefined);
                setSelectedZip(undefined);
                setSelectedCountry(newCountry);
            }}
            />
            </FormControl>
            <FormControl component="fieldset" fullWidth className="spacing double margin top">
            <FormLabel component="legend" style={{ textAlign: 'left' }} className="spacing margin bottom">
            Dein Wohnort
            </FormLabel>
            <LocationAutocomplete
            countryCode={selectedCountry}
            value={selectedCity}
            onLocationMatched={(city, zip) => {
                setSelectedZip(zip);
                setSelectedCity(city);
            }}
            placeholder=""
            />
            </FormControl>
            <FormControl component="fieldset" fullWidth className="spacing double margin top">
            <FormLabel component="legend" style={{ textAlign: 'left' }} className="spacing double margin bottom">
            Dein Geburtsdatum
            </FormLabel>
            <DateSelect selectedDate={selectedDate} onChange={handleDateChange} />
            </FormControl>
            <FormControl component="fieldset" fullWidth className="spacing double margin top flex align-items-start">
            <FormLabel component="legend" style={{ textAlign: 'left' }}>
            Geschlecht
            </FormLabel>

            <div style={{ minWidth: 210 }} className="flex spacing padding top bottom">
            <GenderSelectButtonGroup gender={gender} onGenderChange={setGender} options={defaultOptions} />
            </div>
            </FormControl>

            <FormControl component="fieldset" fullWidth className="spacing double margin top flex align-items-start">
            <FormLabel component="legend" style={{ textAlign: 'left' }}>
            Ich suche nach...
            </FormLabel>

            <div style={{ minWidth: 210 }} className="flex spacing padding top bottom">
            <GenderSelectButtonGroup gender={searchGender} onGenderChange={setSearchGender} options={genderSearchOptions} />
            </div>
            <FormControlLabel
            className="user-select-none"
            style={{
                marginBottom: 24,
                textAlign: 'left',
            }}
            control={<Checkbox value={optIn} onChange={() => setOptIn(!optIn)} required />}
            label={
                <>
                Ich habe die{' '}
                <Link target="_blank" href={AGB_PATH}>
                AGB
                </Link>{' '}
                und{' '}
                <Link target="_blank" href={DATA_PROTECTION_POLICY_PATH}>
                Datenschutzrichtlinien
                </Link>{' '}
                gelesen und akzeptiere sie.
                </>
            }
            />
            <div style={{margin: 'auto'}}>
                {loading && <ActivityIndicator />}
            </div>
            </FormControl>
            
            </>
            </Modal>
            );
});

export default GoogleRegisterComponent;
