import { IResourceDictionary } from './../models/IResourceDicitionary';
import Config from './../../../config';

/* eslint-disable max-len */

export const de: IResourceDictionary = {
    EMPTY_INPUT: 'Du musst das Feld ausfüllen',
    EMPTY_EMAIL_INPUT: 'Du musst deine E-Mail Adresse eingeben, um dich Einloggen zu können.',
    EMPTY_PASSWORD_INPUT: 'Du musst dein Passwort eingeben, um dich Einloggen zu können.',
    EMPTY_NAME_INPUT: 'Du musst deinen Namen eingeben, um dich Registrieren zu können.',
    EMPTY_LOCATION_INPUT: 'Du musst deinen Wohnort eingeben, um dich Registrieren zu können.',
    EMPTY_ZIP_INPUT: 'Bitte wähle einen Ort aus den Vorschlägen aus, um fortzufahren',
    EMPTY_IMAGE_INPUT: 'Du brauchst ein Profilbild, um dich Registrieren zu können',
    WRONG_EMAIL_OR_PASSWORD: 'E-Mail oder Password falsch!',
    WRONG_EMAIL: 'E-Mail Adresse falsch!',
    WRONG_PASSWORD: 'Passwort falsch!',
    NOT_VALID_EMAIL: 'Bitte geben Sie eine valide E-Mail an!',
    DIALOG_PASSWORD_CHANGEREQUEST: 'Wir haben dir eine E-Mail mit der Anleitung zum Zurücksetzen deines Passworts geschickt.',
    DIALOG_ALLOW_GALERIE_ACCESS: 'Du musst ' + Config.GLOBAL_SITE_NAME + ' den Zugriff auf deine Galerie erlauben. Du kannst das in deinen Einstellungen tun.',
    DIALOG_ALLOW_CAMERA_ACCESS: 'Du musst ' + Config.GLOBAL_SITE_NAME + ' den Zugriff auf deine Kamera erlauben. Du kannst das in deinen Einstellungen tun.',
    DIALOG_INVALID_FILE: 'Videos sind nicht zugelassen!',
    DIALOG_WS_CONNECTION_LOST_TEXT: 'Die Verbindung zum Chatservice wurde unterbrochen, es wird bereits versucht sich neu zu verbinden.',
    ALERT_SESSION_EXPIRED_TITLE: 'Sitzung abgelaufen',
    ALERT_SESSION_EXPIRED_TEXT: 'Melde dich bitte erneut an.',
    ALERT_OFFLINE_TITLE: 'Keine Internetverbindung!',
    ALERT_OFFLINE_TEXT: 'Bitte überprüfe deine Internetverbindung.',
    ALERT_DELETE_CHAT_TITLE: 'Chat löschen',
    ALERT_DELETE_CHAT_TEXT: 'Bist du dir sicher, dass du den Chat löschen möchtest?',
    SKIPPED_START_JOURNEY: 'Starte die Reise deines Lebens',
    LOGIN_EMAIL: 'Mit E-Mail anmelden',
    LOGIN_FACEBOOK: 'Mit Facebook anmelden',
    LOGIN_APPLE: 'Mit Apple fortfahren',
    LOGIN_SAFETY: 'Mit deiner Anmeldung akzeptierst du unsere',
    LOGIN_TERMS: 'Nutzungsbedingungen',
    LOGIN_PRIVACY: 'Datenschutzrichtlinie',
    LOGIN_EMAIL_INPUT: 'E-Mail',
    LOGIN_PASSWORD: 'Passwort',
    LOGIN_FORGOT_PASSWORD: 'Passwort vergessen?',
    LOGIN_BUTTON: 'Anmelden',
    LOGIN_NOT_YET: 'Noch nicht dabei?',
    REGISTER_EMAIL: 'Mit E-Mail Registrieren',
    REGISTER_EMAIL_DESCRIPTION:
        'Deine E-Mail-Adresse brauchst du, um dich bei ' + Config.GLOBAL_SITE_NAME + ' registrieren & einloggen zu können. Nur du kannst deine E-Mail später sehen.',
    REGISTER_NAME_FORENAME: 'Vorname',
    REGISTER_NAME_DESCRIPTION: 'Wir wollen dich noch besser kennenlernen. Wie können wir dich Ansprechen und woher kommst du?',
    REGISTER_IMAGE_DESCRIPTION:
        'Um bei ' + Config.GLOBAL_SITE_NAME + ' gleich loslegen zu können, brauchst du ein Profilbild. So wird es dir einfacher Fallen dich mit anderen zu verbinden.',
    REGISTER_PERSONAL_DATA_DESCRIPTION: 'Wir wollen dich noch besser kennenlernen. Wie alt bist du? Als was können wir dich identifizieren?',
    REGISTER_PERSONAL_DATA_BIRTHDATE: 'Geburtstag',
    REGISTER_FAILED_TITLE: 'Registrierung fehlgeschlagen',
    DAY: 'Tag',
    MONTH: 'Monat',
    YEAR: 'Jahr',
    REGISTER_PERSONAL_DATA_GENDER: 'Geschlecht',
    REGISTER_PERSONAL_DATA_MAN: 'Mann',
    REGISTER_PERSONAL_DATA_WOMAN: 'Frau',
    REGISTER_PASSWORD_DESCRIPTION: 'Setze ein sicheres Passwort um dein Profil zu schützen.',
    REGISTER_PASSWORD_REQUIREMENT: 'Dein Passwort muss aus mindestens 6 Zeichen bestehen.',
    REGISTER_NOTIFICATION_TITLE: 'Fertig!',
    REGISTER_NOTIFICATION_DESCRIPTION: 'Aktiviere deine Benachrichtigungen, damit du auch wirklich nichts verpasst!',
    REGISTER_NOTIFICATION_BUTTON: 'Registrieren',
    REGISTER_PERSONAL_DATA_AGE: 'Du musst mindestens 18 Jahre alt sein um dich bei ' + Config.GLOBAL_SITE_NAME + ' registrieren zu können!',
    REGISTER_PASSWORD_REQUIREMENTS: 'Das Passwort erfüllt nicht die Anfroderungen',
    REGISTER_PASSWORD_EMPTY: 'Bitte gib ein Passwort ein',
    PASSWORD_TEXT: 'Nachdem du deine E-Mail eingegeben hast, senden wir dir einen Link zu mit dem zu dein Passwort ändern kannst.',
    PASSWORD_BUTTON: 'Passwort zurücksetzen',
    OWN_PROFILE_COINAMOUNT: 'Guthaben: {coins}',
    OWN_PROFILE_CHANGE_PROFILE_PICTURE: 'Profilbild ändern',
    OWN_PROFILE_UPLOAD_PHOTO: 'Foto hochladen',
    OWN_PROFILE_EDIT: 'Profil bearbeiten',
    OWN_PROFILE_NO_IMAGES: 'Für bessere Ergebnisse beim Daten brauchst du Bilder von dir!',
    OWN_PROFILE_NO_IMAGES_BUTTON: 'Bild hochladen',
    OWN_PROFILE_IMAGES_PROFILE_PICTURE: 'Als Profilbild festlegen',
    OWN_PROFILE_IMAGES_DELETE: 'Foto löschen',
    OWN_PROFILE_YOUR_PICTURES: 'Deine Bilder',
    OWN_PROFILE_UPLOAD_IMAGE_TITLE: 'Lade ein Bild von dir hoch',
    DROPZONE_TEXT: 'Drücken oder reinziehen',
    DROPZONE_DRAG_TEXT: 'Hier reinziehen!',
    OWN_PROFILE_NORMAL_IMAGE: 'Öffentlich',
    OWN_PROFILE_PRIVATE_IMAGE: 'Privat',
    OWN_PROFILE_PRIVATE_IMAGE_COINS: 'Anzahl der Coins: {coins}',
    // Profiledetails
    TRAITS_GENDER_TITLE: 'Geschlecht',
    TRAITS_GENDER_WOMAN: 'Weiblich',
    TRAITS_GENDER_MAN: 'Männlich',
    TRAITS_SMOKER_TITLE: 'Raucher',
    TRAITS_SMOKER_YES: 'Ja',
    TRAITS_SMOKER_NO: 'Nichtraucher',
    TRAITS_SMOKER_OCCASIONALLY: 'Gelegenheitsraucher',
    TRAITS_SMOKER_VAPE: 'Dampfer',
    TRAITS_RELATIONSHIP_TITLE: 'Beziehungsstatus',
    TRAITS_RELATIONSHIP_GIVEN: 'Vergeben',
    TRAITS_RELATIONSHIP_SINGLE: 'Single',
    TRAITS_RELATIONSHIP_COMPLICATED: 'Es ist kompliziert',
    TRAITS_RELATIONSHIP_OPEN: 'Offene Beziehung',
    TRAITS_RELATIONSHIP_MARRIED: 'Verheiratet',
    TRAITS_RELATIONSHIP_DIVORCED: 'Geschieden',
    TRAITS_RELATIONSHIP_WIDOW: 'Verwitwet',
    TRAITS_EYECOLOR_TITLE: 'Augenfarbe',
    TRAITS_EYECOLOR_BLUE: 'Blau',
    TRAITS_EYECOLOR_GREEN: 'Grün',
    TRAITS_EYECOLOR_BROWN: 'Braun',
    TRAITS_EYECOLOR_BLACK: 'Schwarz',
    TRAITS_EYECOLOR_GREY: 'Grau',
    TRAITS_BODYJEWELRY_TITLE: 'Körperschmuck',
    TRAITS_BODYJEWELRY_TATTOO: 'Tattoo',
    TRAITS_BODYJEWELRY_PIERCED: 'Piercing',
    TRAITS_BODYJEWELRY_BRANDING: 'Branding',
    TRAITS_BODYJEWELRY_TATTOO_AND_PIERCED: 'Tattoo und Piercing',
    TRAITS_BODYJEWELRY_NOTHING: 'Nichts',
    TRAITS_HAIRCOLOR_TITLE: 'Haarfarbe',
    TRAITS_HAIRCOLOR_BLONDE: 'Blond',
    TRAITS_HAIRCOLOR_BROWN: 'Braun',
    TRAITS_HAIRCOLOR_BLACK: 'Schwarz',
    TRAITS_HAIRCOLOR_RED: 'Rot',
    TRAITS_HAIRCOLOR_GREY: 'Grau',
    TRAITS_LIVING_TITLE: 'Wohnsituation',
    TRAITS_LIVING_ALONE: 'Alleine',
    TRAITS_LIVING_PARENTS: 'Bei den Eltern',
    TRAITS_LIVING_UNIVERSITY: 'Studentenheim',
    TRAITS_LIVING_SHARED: 'Wohngemeinschaft',
    TRAITS_LIVING_PARTNER: 'Mit Partner zusammen',
    // Sternzeichen
    TRAITS_STARSIGN_TITLE: 'Sternzeichen',
    TRAITS_STARSIGN_ARIES: 'Widder',
    TRAITS_STARSIGN_TAURUS: 'Stier',
    TRAITS_STARSIGN_GEMINI: 'Zwillinge',
    TRAITS_STARSIGN_CANCER: 'Krebs',
    TRAITS_STARSIGN_LEO: 'Löwe',
    TRAITS_STARSIGN_VIGRO: 'Jungfrau',
    TRAITS_STARSIGN_LIBRA: 'Waage',
    TRAITS_STARSIGN_SCORPIO: 'Skorpion',
    TRAITS_STARSIGN_SAGITTARIUS: 'Schütze',
    TRAITS_STARSIGN_CAPRICORN: 'Steinbock',
    TRAITS_STARSIGN_AQUARIUS: 'Wassermann',
    TRAITS_STARSIGN_PISCES: 'Fische',
    TRAITS_SIZE_TITLE: 'Größe',
    TRAITS_ASKME: 'Frag mich...',

    STRANGER_BLINK: 'Zwinker {name} zu!',
    STRANGER_BLINK_TITLE: 'Sende ein Zwinker!',
    STRANGER_BLINK_SUBTITLE: 'Zeige jetzt, dass du dich für {name} interessierst.',
    STRANGER_BLINK_BUTTON: 'Zwinkern ({Coins}C)',
    STRANGER_BLOCK: 'Benutzer blockieren',
    STRANGER_REPORT: 'Benutzer melden',
    STRANGER_ABOUT: 'Bilder von {name}',
    STRANGER_WHO_IS_BEHIND_THAT_PHOTO: 'Wer steckt hinter dem Foto?',
    STRANGER_WHO_IS_BEHIND_THAT_PROFILE_SUBTITLE: 'Möchtest du das Profil freischalten?',
    STRANGER_WHO_IS_BEHIND_THAT_PHOTO_SUBTITLE: 'Möchtest du das Bild freischalten ?',
    STRANGER_ASK_FOR_IMAGES: 'Frage {name} doch, ob sie mehr Bilder von sich hochladen möchte.',
    STRANGER_ASK_FOR_IMAGES_BUTTON: '{name} schreiben',

    SEARCH_SETTINGS_INTERESTED_IN: 'Interessiert an',
    SEARCH_SETTINGS_AGE: 'Alter',
    SEARCH_SETTINGS_BETWEEN: 'zwischen',
    SEARCH_SETTINGS_DISTANCE: 'Entfernung',
    CHAT_MATCHES: 'Deine Matches',
    CHAT_EMPTYSTATE: 'Versuche im ' + Config.GLOBAL_SITE_NAME + ' Game andere Nutzer zu matchen!',

    CHAT_YOU: 'Du:',
    CHAT_DELETE_TITLE: 'Chat löschen?',
    CHAT_DELETE_TEXT: 'Bist du dir sicher, dass du den Chat löschen möchtest?',
    CHAT_GOT_A_BLINK: '{name} hat dir zugezwinkert 😉',
    CHAT_SENT_A_BLINK: 'Du hast {name} einen Zwinker geschickt 😉',
    CHAT_GOT_A_PRESENT: '{name} hat dir ein Geschenk geschickt 🎁',
    CHAT_SENT_A_PRESENT: 'Du hast ein Geschenk geschickt 🎁',
    CHAT_INTERFACE_EMPTYSTATE: 'Mach den ersten Schritt und schreibe {name}!',
    CHAT_INTERFACE_TODAY: 'Heute',
    CHAT_INTERFACE_YESTERDAY: 'Gestern',
    CHAT_INTERFACE_SEND_A_MESSAGE: 'Sende eine Nachricht...',
    PRESENTS_TITLE: 'Wähle dein Geschenk für {name}',
    MENU_SETTINGS: 'Einstellungen',
    MENU_SEARCHSETTINGS: 'Sucheinstellungen',

    SETTINGS_BUY_COINS: 'Coins aufladen',
    SETTINGS_SEARCHSETTINGS: 'Meine Sucheinstellungen',
    SETTINGS_IMPRINT: 'Impressum',
    SETTINGS_DATASAFETY: 'Datenschutz',
    SETTINGS_AGB: 'AGB',
    SETTINGS_LOGOUT: 'Abmelden',

    SETTINGS_ACCOUNT_SETTINGS: 'Kontoeinstellungen',
    SETTINGS_APP_SETTINGS: 'App-Einstellungen',
    SETTINGS_HELP_SUPPORT_TITLE: 'Hilfe & Support',
    SETTINGS_HELP_SUPPORT_BUSINESS_REQUEST: 'Geschäftsanfragen',
    SETTINGS_HELP_SUPPORT_CONTACT: 'Kontakt & Support',
    SETTINGS_BONUS_CODE_TEXT: 'Jetzt Bonuscode einlösen',
    SETTINGS_SHOP_TITLE: 'Jetzt Guthaben aufladen...',
    SETTINGS_SHOP_TEXT: '...und Chatte mit wem du willst!',
    SETTINGS_CONTACT_FORM: 'Kontakt zum Support',

    SETTINGS_LAW: 'Rechtliches',

    SHOP_POPULAR: 'Beliebt',
    SHOP_RECOMMENDED: 'Empfohlen',

    BONUS_CODE_DIALOG_TITLE: 'Bonuscode einlösen',
    BONUS_CODE_DIALOG_INPUT: 'Code eingeben',
    BONUS_CODE_DIALOG_BUTTON: 'Jetzt einlösen',
 
    SHOP_TITLE_STEP_1: '1. Entscheide dich für deine bevorzugte Zahlungsmethode',
    SHOP_TITLE_STEP_2: '2. Entscheide dich für ein passendes Paket',
    SHOP_PAYPAL_TEXT: 'Du wirst zu PayPal weitergeleitet und kannst dort ganz einfach mit deinen Anmeldedaten zahlen.',
    SHOP_KLARNA_TEXT: 'Mit Klarna kannst du direkt von deinem Online Banking aus zahlen und die Coins sofort nutzen.',
    SHOP_CREDIT_CARD_TEXT: 'Schnell und einfach mit deiner Kreditkarte und dem Sicherheitscode bezahlen.',
    SHOP_VORKASSE_TEXT: 'Nach eingang deiner Zahlung (1-2 Banktage) werden dir deine Coins gutgeschrieben.',
    SHOP_PAYSAFECARD_TEXT: 'Du wirst zu Paysafecard weitergeleitet und kannst dort ganz einfach mit deinen Anmeldedaten zahlen.',

    COMPLETE_PROFILE_IMAGE_TITLE: 'Schön, dass du da bist!',
    SELECT_NEW_PROFILE_IMAGE_TITLE: 'Wähle ein passendes Profilbild aus',
    SELECT_NEW_PROFILE_IMAGE_TEXT:
        'Hey {name}, toll, dass du bei ' + Config.GLOBAL_SITE_NAME + ' dabei bist! Lade ein Foto von dir hoch, um deine Chancen auf ein Match zu verbessern!',
    SELECT_NEW_PROFILE_IMAGE_BUTTON: 'Profilbild auswählen',
    EMAIL_NOT_APPROVED_TITLE: 'E-Mail nicht bestätigt',
    EMAIL_NOT_APPROVED_TEXT_NOT_MANDATORY: 'Um Spam vorzubeugen, belohnen wir dich mit kostenlosen Coins, wenn du deine E-Mail bestätigst.',
    EMAIL_NOT_APPROVED_TEXT_MANDATORY: 'Um auf Maluu mit anderen Usern interagieren zu können, musst du deine E-Mail bestätigen.',
    EMAIL_NOT_APPROVED_BUTTON: 'Schließen',
    LOGOUT_MODAL_TITLE: 'Ausloggen',
    LOGOUT_MODAL_TEXT: 'Möchtest du dich wirklich von ' + Config.GLOBAL_SITE_NAME + ' abmelden? Es ist einfacher, eingeloggt zu bleiben und später wieder loszulegen!',
    LOGOUT_MODAL_BUTTON: 'Ausloggen',

    SEARCH_SETTINGS_MALE: 'Männern',
    SEARCH_SETTINGS_FEMALE: 'Frauen',
    SEARCH_SETTINGS_LOCATION: 'Standort',
    SEARCH_SETTINGS_BUTTON: 'Jetzt suchen',
    USER_SEARCH_SIDEBAR_TITLE: 'Benutzersuche',
    USER_SEARCH_PLACEHOLDER: 'Benutzername',
    USER_SEARCH_TITLE: 'Profilsuche',
    USER_SEARCH_EMPTY_STATE: 'Wir konnten keine Benutzer mit dem Namen {name} finden.',
    CHANGE_LOCATION_TITLE: 'Standort ändern',
    STRANGER_BLINK_SMALL: 'Zwinkern',
    STRANGER_ICEBREAKER_BUTTON: 'Eis brechen',
    // ICEBREAKER
    ICEBREAKER_TITLE: 'Dein Eisbrecher an {name}',
    // Basics
    CANCEL: 'Abbrechen',
    NEXT: 'Weiter',
    SAVE: 'Speichern',
    NEW_ON_SKIPPED: 'Neu bei ' + Config.GLOBAL_SITE_NAME,
    NEW_TAG: 'NEU',
    LOCATION_FROM: 'aus',
    LIKE: 'Like',
    LIKED: 'Liked',
    MATCHED: 'matched',
    DISLIKE: 'Dislike',
    UNLOCK: 'Freischalten ({Coins}C)',
    SEND: 'Senden',
    DELETE: 'Löschen',
    BUY: 'Kaufen',
    DISCOVER: 'Jetzt aufdecken!',
    VISITORS: 'Besucher',
    AND: 'und',
    CONFIRM: 'Bestätigen',
    MESSAGE: 'Nachricht',

    MENU_NEARBY: 'Umgebung',
    MENU_MESSAGES: 'Nachrichten',
    MENU_VISITORS: 'Profilbesucher',
    MENU_LIKES: 'Likes',
    MENU_OWN_PROFILE: 'Mein Profil',
    MENU_DARK_MODE: 'Nachtmodus',
    MENU_NEWS: 'Neuigkeiten',

    LIKE_SUCCESS: 'Du hast {name} ein like gegeben!',

    EMPTY_STATE_TITLE: 'Ziemlich leer hier!',
    EMPTY_STATE_DESCRIPTION: 'Schau später nochmal vorbei',

    SETTINGS_CHANGE_LANGUAGE_TITLE: 'Sprache ändern',
    REGISTER_NAME_ZIP: 'Stadt oder Postleitzahl',
    REGISTER_FAILED_UNKNOWN: 'Unbekannter Fehler',
    REGISTER_FAILED_SPACE: 'Dein Name darf kein Leerzeichen enthalten',
    REGISTER_FAILED_NUMBERS: 'Dein Name darf keine Zahlen enthalten',
    REGISTER_FAILED_CAPITALS: 'Dein Name darf nicht mehr als einen Großbuchstaben beinhalten',
    REGISTER_FAILED_LENGTH: 'Dein Name ist zu lang.',
    //TURBOROCKET
    TURBOROCKET_TITLE: 'Hol dir jetzt deinen Boost für mehr Aufmerksamkeit!',
    TURBOROCKET_SUBTITLE: 'Dieser Boost bleibt für 60 Minuten aktiv.',
    TURBOROCKET_COINS: 'Für nur {amount} Coins...',
    TURBOROCKET_RESTOFTIME: 'Für die restliche Zeit...',
    TURBOROCKET_POINT1: 'bekommst du mehr Likes.',
    TURBOROCKET_POINT2: 'besuchen mehr Personen dein Profil.',
    TURBOROCKET_POINT3: 'erscheinst du öfter bei anderen.',
    TURBOROCKET_POINT4: 'steigerst du deine Chance auf ein Match.',
    TURBOROCKET_STARTNOW: 'Jetzt Starten ({amount}C)',
    TURBOROCKET_ALREADY_ACTIVE: 'Dein Bonus ist noch aktiv!',
    TURBOROCKET_INSUFFICIENT_COINS: 'Du hast zu wenig Coins um eine Turborakete zu starten.',
    // CHAT-FAVS
    FAVORITE_CHATS: 'Chats',
    FAVORITE_FAVORITES: 'Favoriten',
    FAVORITE_STARS: 'Dein Sternekonto:',
    FAVORITE_MODAL_TITLE: 'Kaufe neue Sterne für dein Sternekonto',
    FAVORITE_STARS_AMOUNT: '{stars} Sterne',
    FAVORITE_STARS_RECEIVED: 'Du hast {stars} Sterne erhalten!',
    FAVORITE_UNKNOWN_ERROR: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut oder wende dich an den Support.',
    // CountryPopper
    COUNTRY_TITLE: 'Land auswählen',
    COUNTRY_GERMANY: '🇩🇪 Deutschland',
    COUNTRY_AUSTRIA: '🇦🇹 Österreich',
    COUNTRY_SWITZERLAND: '🇨🇭 Schweiz',
    COUNTRY_USA: '🇺🇸 USA',
    //Unlock modal
    UNLOCK_INSUFFICIENT_COINS: 'Du hast leider nicht genügend Coins.',
    UNLOCK_TITLE: 'Wer steckt hinter dem Foto?',
    UNLOCK_TEXT: 'Schalte jetzt das Profil frei um zu sehen, wer Interesse an dir zeigt.',
    UNLOCK_INSUFFICIENT_COINS_BUTTON: 'Jetzt Coins aufladen',
    // Blocking users
    BLOCKED_USERS_TITLE: 'Blockierte Nutzer',
    BLOCKED_USERS_UNLOCK_TITLE: '{name} freigeben',
    BLOCKED_USERS_UNLOCK_TEXT: 'Willst du {name} freigeben? {pronoun} kann dir dann wieder Nachrichten senden und mit dir interagieren.',
    BLOCKED_USERS_UNBLOCK_ITEM_TEXT: 'Entblocken',
    BLOCKED_USERS_EMPTY: 'Du hast keine Nutzer blockiert.',
    //Delete Account
    DELETE_ACCOUNT_TITLE: 'Account löschen',
    DELETE_ACCOUNT_TEXT:
        'Bist du dir wirklich sicher, dass du deinen Account mit sämtlichen Coins und Inhalten löschen willst? (Dieser Schritt kann nicht rückgängig gemacht werden.)',

    UNLOCK_IMAGE_TITLE: 'Was steckt hinter dem Foto?',
    UNLOCK_IMAGE_TEXT: 'Möchtest du das Bild von {name} freischalten?',
    UNLOCK_IMAGE_BUTTON: 'Freischalten ({coins}C)',
    DAILY_LOGIN_DIALOG_TITLE: 'Du erhälst Bonus Coins!',
    DAILY_LOGIN_DIALOG_TEXT: `Für deine heutige Anmeldung erhälst du {coins} Coins.`,

    LANG_DE: 'Deutsch',
    LANG_EN: 'Englisch',

    INSUFFICIENT_COINS_TITLE: 'Oh nein!',
    INSUFFICIENT_COINS_TEXT: 'Du hast leider nicht mehr genug Coins übrig. Du kannst deine Coins im Shop wieder aufladen.',
    INSUFFICIENT_COINS_BUTTON: 'Zum Shop',

    HEADER_GREETER: 'Willkommen auf ' + Config.GLOBAL_SITE_NAME + ' - {name}!',
    NEWSFEED_TITLE: 'Deine Neuigkeiten',
    NEWSFEED_VERIFY: 'Verifiziere dich!',
    NEWSFEED_VERIFIED: 'Du bist jetzt verifiziert!',    
    NEWSFEED_PROFILE_PICUTRE: 'Profilbild',
    NEWSFEED_EMAIL_VERIFICATION: 'E-Mail bestätigt',
    NEWSFEED_APP_DOWNLOADED: 'App heruntergeladen',

    ALERT_LOGIN_FAILURE: 'Fehler bei der Anmeldung!',
    ALERT_UPDATE_SEARCH_SETTINGS: 'Sucheinstellungen erfolgreich übernommen!',
    ALERT_UPDATE_SEARCH_SETTINGS_FAILURE: 'Sucheinstellungen konnten nicht gespeichert werden!',
    ALERT_UPDATE_PROFILE_PICTURE: 'Profilbild erfolgreich geändert!',
    ALERT_UPDATE_PROFILE_PICTURE_FAILURE: 'Profilbild konnte nicht geändert werden!',
    ALERT_UPLOAD_PICTURE: 'Bild erfolgreich hochgeladen!',
    ALERT_UPLOAD_PICTURE_FAILURE: 'Bild konnte nicht hochgeladen werden!',
    ALERT_DELETE_PICTURE: 'Bild gelöscht!',
    ALERT_DELETE_PICTURE_FAILURE: 'Bild konnte nicht gelöscht werden!',
    ALERT_UPDATE_LOCATION: 'Standort wurde geändert!',
    ALERT_UPDATE_LOCATION_FAILURE: 'Standort konnte nicht geändert werden!',
    ALERT_UPDATE_PROFILE: 'Eigenschaften erfolgreich geändert!',
    ALERT_UPDATE_PROFILE_FAILURE: 'Eigenschaften konnten nicht geändert werden!',
    ALERT_SET_FAVORIT: '{name} wurde zu deinen Favoriten hinzugefügt!',
    ALERT_SET_FAVORIT_FAILURE: '{name} konnte nicht zu deinen Favoriten hinzugefügt werde!',
    ALERT_DELETE_ACCOUNT: 'Dein Account wurde erfolgreich gelöscht!',
    ALERT_UNSET_FAVORIT: 'Du hast {name} aus deinen Favoriten gelöscht.',
    ALERT_BLOCK_USER: 'Du hast {name} blockiert!',
    ALERT_REGISTER: 'Geschafft! Du bist jetzt Startklar und kannst beginnen, dich mit Leuten aus deiner Umgebung zu verbinden.',
    ALERT_REGISTER_FAILURE: 'Bei der Registrierung ist ein Fehler aufgetreten! Bitte versuche es später nocheinmal.',
    ALERT_REDEEM_BONUS_CODE: 'Dir wurden {coins} Coins gutgeschrieben!',
    ALERT_REGISTER_FAILURE_DOUBLEIP: 'Fehler - Doppelte Anmeldung erkannt! Nutze deinen vorhandenen Account.',
    ALERT_REGISTER_FAILURE_INVALIDMAIL: 'Diese E-Mail Adresse ist nicht gültig - Nutze eine existierende E-Mail Adresse.',
    ALERT_SUPPORT_MESSAGE: 'Deine Nachricht an den Support wurde übermittelt.',

    MONDAY: 'Montag',
    TUESDAY: 'Dienstag',
    WEDNESDAY: 'Mittwoch',
    THURSDAY: 'Donnerstag',
    FRIDAY: 'Freitag',
    SATURDAY: 'Samstag',
    SUNDAY: 'Sonntag',
    CONTACT_NICKNAME: 'Nickname',
    CONTACT_EMAIL: 'E-Mail',
    CONTACT_SUBJECT: 'Thema',
    CONTACT_MESSAGE: 'Nachricht',
    CONTACT_SUBMIT: 'Senden',
};

export default de;
