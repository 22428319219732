import styled from "styled-components";
// const show = keyframes`
//   0%, 49.99% {
//    opacity: 0;
//    z-index: 1;
//  }

//  50%, 100% {
//    opacity: 1;
//    z-index: 5;
//  }
// `;

// const hide = keyframes`
//   0%, 49.99% {
//     opacity: 1;
//     z-index: 5;
//   }

//   50%, 100% {
//     opacity: 0;
//     z-index: 1;
//   }
// `

export const Container = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 800px;
  max-width: 100%;
  min-height: 600px;
`;

export const SignUpContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  ${props =>
    props.$signingIn !== true
      ? `
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  `
      : null}
`;

export const SignInContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 50%;
  z-index: 2;
  ${props => (props.$signingIn !== true ? `transform: translateX(100%);` : null)}
`;

export const Form = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

export const Title = styled.h1`
  font-weight: bold;
  margin: 0;
`;

export const Input = styled.input`
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
`;

export const Button = styled.button`
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
`;

export const GhostButton = styled(Button)<{$hovervisible?: boolean}>`
  background-color: transparent;
  border-color: #ffffff;
  ${props =>
    props.$hovervisible && props.$hovervisible == true ? `&:hover {
      transition: transform 80ms ease-in;
      background-color: rgba(0.2,0.2,0.2,0.2);
  }` : null}
`;

export const Anchor = styled.a`
  color: #333;
  font-size: 14px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    transition: transform 80ms ease-in;
    color: blue;
  }
`;

export const OverlayContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
  ${props =>
    props.$signingIn !== true ? `transform: translateX(-100%);` : null}
`;

export const Overlay = styled.div<{$signingIn: boolean}>`
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #e44746, #fc7474);
  background: linear-gradient(to right, #e44746, #fc7474);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  ${props => (props.$signingIn !== true ? `transform: translateX(50%);` : null)}
`;

export const OverlayPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
`;

export const LeftOverlayPanel = styled(OverlayPanel)<{$signingIn: boolean}>`
  transform: translateX(-20%);
  ${props => (props.$signingIn !== true ? `transform: translateX(0);` : null)}
`;

export const RightOverlayPanel = styled(OverlayPanel)<{$signingIn: boolean}>`
  right: 0;
  transform: translateX(0);
  ${props => (props.$signingIn !== false ? `transform: translateX(20%);` : null)}
`;

export const MobileContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-height: 90%;
`;

export const MobileSignUpContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: 80;
  height: 80%;
  transition: all 0.6s ease-in-out;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 5;
  transform: translateY(-100%);
  ${props =>
    props.$signingIn == false
      ? `
      z-index: 5;
  transform: translateY(0%);
  opacity: 1;
  z-index: 100;
  `
      : null}
`;

export const MobileSignInContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: -60%;
  height: 80%;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  ${props => (props.$signingIn == true ? 
    `
    z-index: 5;
    opacity: 1;
    transform: translateY(100%);` 
    : null)}
`;

export const MobileOverlayContainer = styled.div<{$signingIn: boolean}>`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 2;
`;

export const MobileOverlay = styled.div<{$signingIn: boolean}>`
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #e44746, #fc7474);
  background: linear-gradient(to right, #e44746, #fc7474);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  position: relative;
  color: #ffffff;
  left: 0%;
  height: 20%;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
  ${props => (props.$signingIn ==  false ? //register
    `
      transform: translateY(399%);
    ` 
    : null)}
`;

export const MobileOverlayPanel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0%;
  height: 100%;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
`;

export const MobileBottomOverlayPanel = styled(MobileOverlayPanel)<{$signingIn: boolean}>`
  transform: translateY(-100%);
  opacity: 0;

  ${props => (props.$signingIn == true ? `
    opacity: 1;
    transform: translateY(0%);
    ` : null)}
`;

export const MobileTopOverlayPanel = styled(MobileOverlayPanel)<{$signingIn: boolean}>`;
  transform: translateY(0%);
  opacity: 1;
  ${props => (props.$signingIn == true ? `
    opacity:0;
    transform: translateY(100%);
    ` : null)}
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
`;
