export class Logger {
    public static log(message?: any, ...params: any[]) {
        if (process.env.NODE_ENV !== 'production') {
            if (params) {
                console.log(message, ...params);
            } else {
                console.log(message);
            }
        }
    }

    public static warn(message?: any, ...params: any[]) {
        if (process.env.NODE_ENV !== 'production') {
            if (params) {
                console.warn(message, ...params);
            } else {
                console.warn(message);
            }
        }
    }
}

export default Logger;
