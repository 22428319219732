export const MAIN_PATH = '/';

export const HOME_PATH = '/';

export const MESSAGES_PATH = '/messages/:id?';

export const LIKES_PATH = '/likes';

export const VISITORS_PATH = '/visitors';

export const PROFILE_PATH = '/profile';

export const STRANGER_PROFILE_PATH = '/stranger-profile/:id?';

export const SHOP_PATH = '/shop';
export const APP_SHOP_PATH = '/appshop';

export const SETTINGS_PATH = '/settings';

export const IMPRESSUM_PATH = '/impressum';

export const AGB_PATH = '/agb';

export const DATA_PROTECTION_POLICY_PATH = '/datenschutz';

export const USER_SEARCH_PATH = '/search/:id?';
export const SUPPORT_PATH = '/support';

export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const FORGOT_PASSWORD_PATHH = '/forgot-passwords';

export const REGISTER_PATH = '/register';
export const COMPLETE_REGISTRATION_PATH = '/complete-registration';

export const PAYMENT_SUCCEEDED_PATH = '/payment-succeeded';

export const NEWS_PATH = '/news';
