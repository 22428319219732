import './Landingpage.scss';
import * as Components from "./Components";

import { faAt, faLock } from '@fortawesome/pro-light-svg-icons';
//import { Button, FormControl, FormControlLabel, FormLabel, Link, TextField, Typography } from '@material-ui/core';
import { Button, Paper, ThemeProvider, Typography, createMuiTheme, useMediaQuery, TextField, FormControl, InputAdornment, FormControlLabel, FormLabel, Card, CardContent, CardActions } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import CookieStorageAPI from '../../core/storage/CookieStorageAPI';
import addMinutes from 'date-fns/addMinutes';
import { IUser } from '../../models/user/IUser';


import NotificationActionCreator from '../../services/Notifications/actions/NotificationActionCreator';
//import Background from '../../assets/images/landingpage/bg3.jpg';
import Background from '../../assets/images/landingpage/output.jpg';
import Background2 from '../../assets/images/landingpage/bg4.jpg';
import Background3 from '../../assets/images/landingpage/bg5.jpg';
import ForgotPasswordModal from '../../components/ForgotPasswordModal';
import ForgotPasswordModalx from '../../components/ForgotPasswordModalx';
import isValidEmail from '../../core/typeguards/isValidEmail';
import Icon from '../../components/Icon';
import RegisterComponent from '../../components/RegisterComponent';
import FinishRegisterComponent from '../../components/FinishRegisterComponent';
import FacebookRegisterComponent from '../../components/FacebookRegisterComponent'; // This is the component for the COMPLETION of a Facebook login
import GoogleRegisterComponent from '../../components/GoogleRegisterComponent'; // This is the component for the COMPLETION of a Google login
import SignInComponent from '../../components/SignInComponent';
import { FORGOT_PASSWORD_PATH, LOGIN_PATH, REGISTER_PATH ,FORGOT_PASSWORD_PATHH} from '../../models/Paths';
import FBLoginButton from '../../services/Facebook/FBLoginButton';
import FacebookLogin from '@greatsumini/react-facebook-login'
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'

import useLoginHandler from '../../services/Facebook/useLoginHandler';
import { DefaultTheme } from '../../theme/DefaultTheme';

import CookiesWarningDialog from './components/CookiesWarningDialog';
import Footer from './components/Footer';
import Config from '../../config'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { DirectInteractionActionCreator } from '../../actions/DirectInteractionActionCreator'
import AuthenticationActionCreator from '../../actions/AuthenticationActionCreator';
import { BerndLoginArts } from '../../temp/models/IBerndLoginCredentials';
import MD5 from 'md5'
const usnLsKey = btoa('usn')
const passwdLsKey = btoa('pswd')
//import Logo from '../../assets/images/logos/logo-with-writing.png';
import Logo from '../../assets/images/logos/LogoSlogan.png';
import Herz from '../../assets/images/icons/mogen.png';
import Stift from '../../assets/images/icons/stift.png';
import Lupe from '../../assets/images/icons/suche.png';
//import GenderSelectButtonGroup from '../../components/GenderSelectButtonGroup';
//import { GenderSearchTraits, GenderTraits } from '../../temp/models/BerndUserTraits';
import { getAuthenticationError } from '../../selectors/AuthenticationSelectors';
import useTranslation from '../../services/i18n/core/useTranslation';


export interface ILandingpageProps {}

export const Landingpage = React.memo((props: ILandingpageProps) => {

    const isDesktop = useMediaQuery('(min-width:900px)', { defaultMatches: true });

    const isMobile = useMediaQuery('(max-width:600px)', { defaultMatches: true });

    const history = useHistory();
    const dispatch = useDispatch();

    const [isFacebookModalOpen, setFacebookModalOpen] = useState(false);
    const [loginResultFacebook, setLoginResultFacebook] = useState({});

    const [isGoogleModalOpen, setGoogleModalOpen] = useState(false);
    const [loginResultGoogle, setLoginResultGoogle] = useState({});

    const searchParams = new URLSearchParams(location.search);

    const [modalFinishRegisterOpen, setModalFinishRegisterOpen] = useState(false)
    
    const [signIn, toggle] = useState(false);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState<string>("");
    const [repeatPW, setRepeatPW] = useState<string>(""); 

    const [errorHelperText, setErrorHelperText] = useState('');
    const error = useSelector(getAuthenticationError);
    const { EMPTY_EMAIL_INPUT, EMPTY_PASSWORD_INPUT } = useTranslation();

    useEffect(() => {
        if (error?.Note === 'Error Password') {
            setErrorHelperText('Email oder Passwort falsch!');
        }
    }, [error]);

    async function handleLogin() {
        if (email.trim() === '' || password.trim() === '') {
            // check if the email is faulty
            //Alert.alert('Anmeldung fehlgeschlagen!', email === '' ? EMPTY_EMAIL_INPUT : EMPTY_PASSWORD_INPUT);
            dispatch(
                NotificationActionCreator.enqueueSnackbar({
                    key: String(new Date().getTime() + Math.random()),
                    message: email === '' ? EMPTY_EMAIL_INPUT : EMPTY_PASSWORD_INPUT,
                    options: { variant: 'error' },
                })
            );
        } else if (isValidEmail(email)) {
            // if everything is ok we can proceed by submitting the credentials
            // check if login was successful if not, display error message
            CookieStorageAPI.setItem('loggedIN', 'true', { expires: addMinutes(new Date(), 5) });
            
            await dispatch(AuthenticationActionCreator.loginUser({ email, password }))    
            persistToLS(email, password);
        } else {
             dispatch(
                NotificationActionCreator.enqueueSnackbar({
                    key: String(new Date().getTime() + Math.random()),
                    message: "Bitte gib eine gültige Email adresse ein.",
                    options: { variant: 'error' },
                })
                );
        }
    }


    const handleForgotPassword = useCallback(() => {
        history.push(FORGOT_PASSWORD_PATH);
    }, [history]);

    async function handleSocialUserLogin(email: string, password: string, art?: BerndLoginArts, facebook_id?: string, google_id?: string) {
        // CookieStorageAPI.setItem('loggedIN', 'true', { expires: addMinutes(new Date(), 5) });
        const result = await dispatch(
            AuthenticationActionCreator.loginUser({ email, password, art, facebook_id, google_id })
        )
        
        if (result) {
        // this doesnt work with facebook login
            persistToLS(email, password)
        } else {
            removeFromLs()
        }
    }

  const handleFBLogin = async (response:object) => {
    const res = await (await DirectInteractionActionCreator.isExistingUser(response["email"], response["id"])).json()
    if (res[0].email_exists) {
        if (!res[0].facebook_id_exists) {
                // add social Media
            const res = await (await DirectInteractionActionCreator.addSocialMedia(response["email"], response["id"])).json()
        }
            // handle login
        await handleSocialUserLogin(response["email"], MD5(response["id"]), BerndLoginArts.FACEBOOK, response["id"])
    } else {
        setLoginResultFacebook(response)
        setFacebookModalOpen(true)
    }
}

const googlelogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse)
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      )
      console.log(userInfo)
      const res = await (await DirectInteractionActionCreator.isExistingUser(userInfo.data.email, undefined, userInfo.data.sub)).json()
      console.log(res)
      if (res[0].email_exists) {
        // account exists
        if (!res[0].google_id_exists) {
          // add social Media
          const res = await (await DirectInteractionActionCreator.addSocialMedia(userInfo.data.email, undefined, userInfo.data.sub)).json()
          console.log(res)
        }
        // handle login
        console.log('handle login')
        await handleSocialUserLogin(userInfo.data.email, MD5(userInfo.data.sub), BerndLoginArts.GOOGLE, undefined, userInfo.data.sub)
      } else {
        setLoginResultGoogle(userInfo.data)
        setGoogleModalOpen(true)
      }
    },
    onError: errorResponse => console.log(errorResponse)

  })

const handlenextRegisterPage = async () => {
    if (email.trim() === '' || password.trim() === '') {
        // check if the email is faulty
        //Alert.alert('Anmeldung fehlgeschlagen!', email === '' ? EMPTY_EMAIL_INPUT : EMPTY_PASSWORD_INPUT);
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: email === '' ? EMPTY_EMAIL_INPUT : EMPTY_PASSWORD_INPUT,
                options: { variant: 'error' },
            })
        );
        return
    }
    if (repeatPW.trim() === '') {
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: "Bitte Passwort wiederholen.",
                options: { variant: 'error' },
            })
        );
        return
    }
    if (!isValidEmail(email.trim())) {
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: "Email ungültig. Bitte gib eine gültige Email ein.",
                options: { variant: 'error' },
            })
        );
        return
    }
    if (password.trim().length < 6) {
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: "Passwort zu kurz.",
                options: { variant: 'error' },
            })
        );
        return
    }
    if (password.trim() !== repeatPW.trim()) {
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: "Passwörter stimmen nicht überein.",
                options: { variant: 'error' },
            })
        );
        return
    }
    //setNumPage(2)
    setModalFinishRegisterOpen(true)
};


const handleEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (errorHelperText) {
        setErrorHelperText('');
        dispatch(AuthenticationActionCreator.cancelAuthentication());
    }
    if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
        if (signIn){
            await handleLogin();    
        } else {
            await handlenextRegisterPage();
        }
    }
};

const backgroundGradient = `url(${Background})`;

const desktopPage1 = (
    (
        //Desktop
    <Components.Container>
      <Components.SignUpContainer $signingIn={signIn}>
        <Components.Form>
          <Components.Title style={{marginBottom:20}}>Jetzt registrieren!</Components.Title>
        <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalOpen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Facebook registrieren</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton onClick={googlelogin} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Google registrieren</span>
            </GoogleLoginButton>
            <Typography>oder</Typography>
            <TextField
                style={{ marginBottom: 16,marginTop:16 }}
                fullWidth
                label="Deine E-Mail Adresse"
                type="email"
                id="email-input"
                key="email"
                autoComplete="email"
                value={email}
                onKeyPress={handleEnter}
                onChange={(e) => setEmail(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faAt} />
                            </InputAdornment>
                        ),
                    }}
            />
            <TextField
                style={{ marginBottom: 16 }}
                fullWidth
                label="Dein Passwort"
                type="password"
                autoComplete="new-password"
                onKeyPress={handleEnter}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                    }}
            />
            <TextField
                style={{ marginBottom: 16 }}
                fullWidth
                label="Passwort wiederholen"
                autoComplete="new-password"
                type="password"
                value={repeatPW}
                onKeyPress={handleEnter}
                onChange={(e) => setRepeatPW(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                    }}
            />
            <Button color="secondary" onClick={handlenextRegisterPage}>mit E-Mail registrieren</Button>
          {/*<Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>mit Email registrieren</Components.Button>*/}
        </Components.Form>
      </Components.SignUpContainer>
      <Components.SignInContainer $signingIn={signIn}>
        <Components.Form>
          <Components.Title style={{marginBottom:20}} >Jetzt einloggen</Components.Title>
           <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalOpen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Facebook einloggen</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton onClick={googlelogin} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Google einloggen</span>
            </GoogleLoginButton>
          
            <Typography>oder</Typography>
                      <TextField
                style={{ marginBottom: 16,marginTop:16 }}
                fullWidth
                label="Deine E-Mail Adresse"
                type="email"
                id="email-input"
                key="email"
                autoComplete="email"
                error={Boolean(errorHelperText)}
                onKeyPress={handleEnter}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faAt} />
                            </InputAdornment>
                        ),
                    }}
            />
            <TextField
                style={{ marginBottom: 16 }}
                fullWidth
                label="Dein Passwort"
                type="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                    }}
            />
            <Button color="secondary" onClick={handleLogin}>mit E-Mail Einloggen</Button>
          {/*<Components.Button onClick={handleLogin} style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>Mit Email einloggen</Components.Button>*/}
          <Components.Anchor onClick={handleForgotPassword}>Passwort vergessen?</Components.Anchor>
        </Components.Form>
      </Components.SignInContainer>
      <Components.OverlayContainer $signingIn={signIn}>
        <Components.Overlay $signingIn={signIn}>
          <Components.LeftOverlayPanel $signingIn={signIn}>
            <Components.Title>Du bist bereits Mitglied?</Components.Title>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
              Melde dich hier in deinem persönlichen Konto an und verpasse keine Möglichkeit, deinen passenden Partner zu
              finden.
            </Components.Paragraph>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
              Vielleicht ist der nächste Schritt nur einen Klick entfernt.
            </Components.Paragraph>
            {/*<Components.Paragraph style={{fontWeight:600, fontSize:15}}>
              Logge dich hier in dein persönliches Konto ein und verpasse keine Gelegenheit deinen Traumpartner zu finden.
            </Components.Paragraph>*/}
            <Components.GhostButton $hovervisible={!signIn} onClick={() => toggle(true)}>
              Einloggen
            </Components.GhostButton>
          </Components.LeftOverlayPanel>
          <Components.RightOverlayPanel $signingIn={!signIn}>
            <Components.Title>Du bist neu hier?</Components.Title>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
            Bei MALUU entdeckst du täglich neue Begegnungen. Dein Match könnte nur einen Schritt
            entfernt sein.
            </Components.Paragraph>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
               Melde dich jetzt an und erlebe aufregende Flirts.
            </Components.Paragraph>
            <Components.GhostButton $hovervisible={signIn} onClick={() => toggle(false)}>
              Registrieren
            </Components.GhostButton>
          </Components.RightOverlayPanel>
        </Components.Overlay>
      </Components.OverlayContainer>
    </Components.Container>
    )
    )


const mobilePage1 = (
    <Components.MobileContainer>
      <Components.MobileSignUpContainer $signingIn={signIn}>
        <Components.Form>          
          <Components.Title>Jetzt registrieren!</Components.Title>
            <FacebookLogin
                appId={Config.FACEBOOK_APP_ID}
                scope="public_profile, email"
                fields="name,email,picture"
                onSuccess={(response) => {
                    // console.log('Login Success!', response)
                    // console.log(typeof response)
                }}
                onFail={(error) => {
                    //console.log('Login Failed!', error)
                    setFacebookModalOpen(false)
                }}
                onProfileSuccess={ handleFBLogin }
                render={({ onClick, logout }) => (
                      <FacebookLoginButton onClick={onClick} style={{maxHeight:45, minWidth:'280px', fontSize:16}}>
                          <span style={{fontWeight:400, fontSize:16}}>Mit Facebook registrieren</span>
                      </FacebookLoginButton>
                    )}/>
            <GoogleLoginButton onClick={googlelogin} style={{maxHeight:45, minWidth:'280px', marginTop:5, fontSize:16}}>
                <span style={{fontWeight:400, fontSize:16}}>Mit Google registrieren</span>
            </GoogleLoginButton>
            <Typography style={{fontSize:16}}>oder</Typography>
                        <TextField
                style={{ marginBottom: 16,marginTop:16 }}
                fullWidth
                label="Deine E-Mail Adresse"
                type="email"
                id="email-input"
                key="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faAt} />
                            </InputAdornment>
                        ),
                        style: {fontSize: 16},
                    }}
                InputLabelProps={{style: {fontSize: 16}}}
            />
            <TextField
                style={{ marginBottom: 16, fontSize:16 }}
                fullWidth
                label="Dein Passwort"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                        style: {fontSize: 16},
                    }}
                InputLabelProps={{style: {fontSize: 16}}}
            />
            <TextField
                style={{ marginBottom: 16, fontSize:16 }}
                fullWidth
                label="Passwort wiederholen"
                autoComplete="new-password"
                type="password"
                value={repeatPW}
                onChange={(e) => setRepeatPW(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                    style: {fontSize: 16},
                    }}
                InputLabelProps={{style: {fontSize: 16}}}
            />
            <Button color="secondary" onClick={handlenextRegisterPage} style={{fontSize:16}}>mit E-Mail registrieren</Button>
          {/*<Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>mit Email registrieren</Components.Button>*/}
        </Components.Form>
      </Components.MobileSignUpContainer>
      <Components.MobileSignInContainer $signingIn={signIn}>
        <Components.Form>
          <Components.Title>Jetzt einloggen</Components.Title>
                     <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalOpen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40, minWidth:'280px'}}>
                <span style={{fontWeight:400, fontSize:16}}>Mit Facebook einloggen</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton onClick={googlelogin} style={{maxHeight:40, minWidth:'280px'}}>
                <span style={{fontWeight:400, fontSize:16}}>Mit Google einloggen</span>
            </GoogleLoginButton>
            <Typography>oder</Typography>
                      <TextField
                style={{ marginBottom: 16,marginTop:16, fontSize:16 }}
                fullWidth
                label="Deine E-Mail Adresse"
                type="email"
                id="email-input"
                key="email"
                onKeyPress={handleEnter}
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faAt} />
                            </InputAdornment>
                        ),
                    style: {fontSize: 16},
                    }}
                InputLabelProps={{style: {fontSize: 16}}}
            />
            <TextField
                style={{ marginBottom: 16, fontSize:16 }}
                fullWidth
                label="Dein Passwort"
                type="password"
                onKeyPress={handleEnter}
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon={faLock} />
                            </InputAdornment>
                        ),
                    style: {fontSize: 16},
                    }}
                InputLabelProps={{style: {fontSize: 16}}}
            />
          {/*<Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>Mit Email einloggen</Components.Button>*/}
          <Button color="secondary" onClick={handleLogin}>mit E-Mail Einloggen</Button>
          <Components.Anchor href="#">Passwort vergessen?</Components.Anchor>
        </Components.Form>
      </Components.MobileSignInContainer>
      <Components.MobileOverlayContainer $signingIn={signIn}>
        <Components.MobileOverlay $signingIn={signIn}>
          <Components.MobileTopOverlayPanel $signingIn={signIn}>
            <Components.Title style={{fontSize:20}}>Du bist bereits Mitglied?</Components.Title>
            <Components.GhostButton $hovervisible={!signIn} onClick={() => toggle(true)}>
              Einloggen
            </Components.GhostButton>
          </Components.MobileTopOverlayPanel>
          <Components.MobileBottomOverlayPanel $signingIn={signIn}>
            <Components.Title style={{fontSize:20}}>Du bist neu hier?</Components.Title>
            <Components.GhostButton $hovervisible={signIn} onClick={() => toggle(false)}>
              Registrieren
            </Components.GhostButton>
          </Components.MobileBottomOverlayPanel>
        </Components.MobileOverlay>
      </Components.MobileOverlayContainer>
    </Components.MobileContainer>
        );

return (
    <ThemeProvider theme={createMuiTheme(DefaultTheme, deDE)}>
    <div style={{backgroundColor:'white'}}>
    <div
    className="flex container-one"
    style={{
        //backgroundColor: '#514f51',
        backgroundImage: backgroundGradient,
        minHeight: '100vh',
        backgroundPosition: 'top',
        //clipPath: 'circle(120vh at 50% 0%)',
        borderRadius: '0% 0% 50% 50%/ 5%'
    }}
    >
    <div
    className="flex container-one"
    style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        minHeight: '100vh',
        backgroundPosition: 'top',
        borderRadius: '0% 0% 50% 50%/ 5%'
    }}
    >
    <div
    className={`flex ${isDesktop ? 'column' : 'column'} container ${
        isDesktop ? 'justify-content-center' : 'justify-content-center'
    } align-items-center`}
    style={{ paddingTop: 30, paddingBottom: 100 }}
    >
    <div style={isDesktop ? {} : { textAlign: 'center', marginBottom: 24 }}>
    </div>

    <div style={{ paddingTop: 0, paddingBottom: 10, justifyContent:'center', alignItems:'center', display:'flex' }}>
        {isMobile ? (<img src={Logo} style={{maxHeight:'50px'}}/>) : (<img src={Logo} style={{maxHeight:'100px'}}/>) }
    </div>
    {/*<Typography
    variant={isDesktop ? 'h5' : 'h5'}
    style={{ color: '#fff', textShadow: 'rgb(0 0 0 / 29%) 0px 2px 1px', fontWeight: 700, marginTop: , marginBottom:20 }}
    >
        Du bist nur ein Schritt entfernt davon neue Menschen aus deiner Nähe kennenzulernen!
    </Typography>*/}

    {isMobile ? mobilePage1 : desktopPage1}

    </div>    
    </div>
    </div>
    <div>
    <Paper className="flex row wrap" style={{justifyContent:'center'}}>
    <Typography variant="h4" style={{width:'100%', textAlign:'center', marginTop:30}}>
          Besonderheiten
    </Typography>
    <Card variant="outlined"  style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'0px 0px 2px 1px rgb(0 0 0 / 19%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Herz} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Der ideale Begleiter
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Hier kannst du jemanden finden, der deine Werte, Interessen und Lebensziele teilt und sowohl ein romantischer Partner als auch ein vertrauensvoller Freund fürs Leben sein kann.
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'0px 0px 2px 1px rgb(0 0 0 / 19%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Stift} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Raum für Neues
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Hier findest du den idealen Ort, um jemanden kennenzulernen, der zu dir passt – egal wo du dich befindest. Es geht darum, den passenden Standort für eine besondere Verbindung zu schaffen, die in deinem Leben Raum findet.
        </Typography>
      </CardContent>
    </Card>
        <Card variant="outlined" style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'0px 0px 2px 1px rgb(0 0 0 / 19%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Lupe} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Entdecke unsere Mitglieder
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Erkunde unsere Mitglieder und entdecke Menschen, die deine Interessen und Werte teilen und vielleicht die perfekte Ergänzung in deinem Leben sein könnten.
        </Typography>
      </CardContent>
    </Card>
    </Paper>
        <Footer />
        <Route path={LOGIN_PATH} component={SignInComponent} />
        <Route path={FORGOT_PASSWORD_PATH} component={ForgotPasswordModal} />
        <Route path={FORGOT_PASSWORD_PATHH} component={ForgotPasswordModalx} />
        <Route path={REGISTER_PATH} component={RegisterComponent} />
    </div>
    <GoogleRegisterComponent isOpen={isGoogleModalOpen} loginresult={loginResultGoogle} handleClose={()=>setGoogleModalOpen(false)} />
    <FacebookRegisterComponent isOpen={isFacebookModalOpen} loginresult={loginResultFacebook} handleClose={()=>setFacebookModalOpen(false)} />
    <FinishRegisterComponent email={email} password={password} open={modalFinishRegisterOpen} handleClose={()=>setModalFinishRegisterOpen(false)} />
    <CookiesWarningDialog />
    </div>
    </ThemeProvider>
    );
});

function persistToLS(email: string, password: string) {
    localStorage.setItem(usnLsKey, btoa(email));
    localStorage.setItem(passwdLsKey, btoa(password));
}

function removeFromLs() {
    localStorage.removeItem(usnLsKey);
    localStorage.removeItem(passwdLsKey);
}

function retrieveFromLS() {
    const email = localStorage.getItem(usnLsKey) ?? '';
    const password = localStorage.getItem(passwdLsKey) ?? '';

    return {
        email: atob(email),
        password: atob(password),
    };
}
