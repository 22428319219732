import isArray from '../../../core/typeguards/isArray';
import isObject from '../../../core/typeguards/isObject';

export interface IError {
    message: string;
    Note: string;
    _embedded: IErrorList;
}

interface IErrorList {
    errors: ISingleError[];
}

interface ISingleError {
    message: string;
    constraints: [];
}

export interface IErrorDebug extends IError {
    exception_class: string;
    file: string;
    line: string;
    description: string;
}

export const isError = (x: any): x is IError =>
    isArray<any>(x) && x.length === 1 && 'Note' in x[0] && x[0]['Note'] !== 'Success';

// isObject(x) && 'message' in x
export const isDebugErrorInfo = (x: any): x is IErrorDebug => isObject(x) && 'message' in x;
